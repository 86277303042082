<template>
  <v-container>
    <div class="folha boloifixo">
      <div class="space"></div>
      <div class="content">
        <div class="col1">
          <img
            class="pb"
            src="../../assets/logoEasy2019.png"
            style="height: 50px;"
          />
        </div>
        <div class="col2">
          <img
            class="pb"
            src="../../assets/brt.jpg"
            style="max-height: 80px;"
          />
        </div>
      </div>
      <div class="space"></div>
      <table>
        <tbody>
          <tr>
            <td colspan="3">
              Fatura de Servi&ccedil;os de Telecomunica&ccedil;&otilde;es
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <code>Sacado</code><br />
              <b>{{ nomedevedor }}</b>
            </td>
          </tr>
          <tr>
            <td>
              <code>Nosso N&uacute;mero</code><br />
              {{ nossonumero }}
            </td>
            <td>
              <code>(=) Valor do Documento</code><br />
              R$ {{ valor | dinheiro }}
            </td>
            <td>
              <code>Vencimento</code><br />
              {{ vencimento }}
            </td>
          </tr>
          <tr>
            <td colspan="3">
              Demonstrativo (Faturas)
            </td>
          </tr>
          <tr>
            <td class="long" colspan="3">
              <code>Fixo {{ contrato }}/{{ cpf }}</code
              ><br />
              ATEN&Ccedil;&Atilde;O. PAG&Aacute;VEL EM QUALQUER AG&Ecirc;NCIA
              BANC&Aacute;RIA AT&Eacute; O VENCIMENTO. <br />
              AP&Oacute;S O VENCIMENTO ENTRAR EM CONTATO NA CENTRAL DE
              RELACIONAMENTO <br />
              EASYCOB <br />
              TELEFONES:(21)2206-4873 / 0800-081-0426 <br />
              E-mail: boleto@easboleto.com.br <br />
              FATURA(S) <br />
              <div v-for="(item, i) in faturasTodas" :key="i">
                <code>
                  {{ item.pdnumdoc }} - {{ item.pddtvencto | dataF }} - R$
                  {{ item.pdvalor | dinheiro }}
                </code>
                <br />
              </div>

              <div class="footer">
                Oi S/A <br />
                CNPJ: 76.535.764/0001-43 - Inscr. Estadual: 77685022 <br />
                Rua do Lavradio, 71 – 2&ordm; Andar - Centro <br />
                CEP: 20230-070 – Rio de Janeiro - RJ
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="space"><hr /></div>
      <table>
        <tbody>
          <tr>
            <td colspan="3">
              <div class="col1">
                <img
                  class="pb"
                  src="../../assets/logoEasy2019.png"
                  style="height: 50px;"
                />
              </div>
              <div class="col2">
                <img
                  class="pb"
                  src="../../assets/brt.jpg"
                  style="max-height: 60px;"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <code>Sacado</code><br />
              <b>{{ nomedevedor }}</b>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <code>Endereço</code><br />
              <b>{{ endereco }}</b
              ><br />
              <b>{{ bairro }} {{ cidade }} {{ uf }} {{ cep }}</b>
            </td>
          </tr>
          <tr>
            <td>
              <code>Nosso Número</code><br />
              {{ nossonumero }}
            </td>
            <td>
              <code>(=) Valor do Documento</code><br />
              R$ {{ valor | dinheiro }}
            </td>
            <td>
              <code>Vencimento</code><br />
              {{ vencimento }}
            </td>
          </tr>
          <tr>
            <td class="medium" colspan="3">
              <code>Fixo {{ contrato }}/{{ cpf }}</code
              ><br />
              ATEN&Ccedil;&Atilde;O. PAG&Aacute;VEL EM QUALQUER AG&Ecirc;NCIA
              BANC&Aacute;RIA AT&Eacute; O VENCIMENTO.<br />
              AP&Oacute;S O VENCIMENTO ENTRAR EM CONTATO NA CENTRAL DE
              RELACIONAMENTO<br />
              EASYCOB<br />
              TELEFONES:(21)2206-4873 / 0800-081-0426
            </td>
          </tr>
        </tbody>
      </table>
      <div class="space">
        {{ barcodeVal }}
        <div id="codb"></div>
      </div>
    </div>
  </v-container>
</template>

<script>
import barcode from "../../conf/barcode";
//import utilidade from "../../conf/utilitario";
export default {
  name: "Brt2",
  components: {
    // barcode: VueBarcode,
  },
  data() {
    return {
      nomedevedor: "",
      contrato: "",
      cpf: "",
      nossonumero: "",
      valor: "",
      vencimento: "",
      barcodeVal: "",
      barcordeVal2: "",
      codB: "",
      faturas: "",
      faturasTodas: [],
      endereco: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
      cgc: "",
    };
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  methods: {
    voltarDividas() {
      this.$router.push("dividas");
    },
  },
  async mounted() {
    let boleto = this.$store.state.boleto.data;
    //console.log(boleto);
    barcode(window.$);
    window.$("#codb").barcode({ code: `${boleto.codB}`, crc: false }, "int25", {
      barWidth: 1,
      barHeight: 50,
      showHRI: false,
    });
  },
  async beforeMount() {
    let boleto = this.$store.state.boleto.data;

    this.nomedevedor = boleto.nomedevedor;
    this.contrato = boleto.contrato;
    this.cpf = boleto.cpf;
    this.nossonumero = boleto.nossonumero;
    this.valor = boleto.valor;
    this.vencimento = boleto.vencimento;
    this.barcodeVal = boleto.barcodeVal;
    this.barcordeVal2 = boleto.barcordeVal2;
    this.codB = boleto.codB;
    this.faturas = boleto.faturas;
    this.faturasTodas = boleto.faturasTodas;
    this.endereco = boleto.endereco;
    this.bairro = boleto.bairro;
    this.cidade = boleto.cidade;
    this.uf = boleto.uf;
    this.cep = boleto.cep;
    this.cgc = boleto.cgc;

    await this.$store.dispatch("setMostraBotoes", {
      imprimir: true,
      voltar: false,
    });
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 12px;
  background: white;
}
a img,
img {
  border: none;
}
ol,
ul {
  list-style: none;
  border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
hr {
  border: 0px none;
  height: 1px;
  background-image: -moz-linear-gradient(
    left center,
    transparent,
    rgba(0, 0, 0, 0.75),
    transparent
  );
  margin-top: 4px;
  margin-bottom: 4px;
}
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/* --------------------------------
		geral
--------------------------------- */

i {
  color: red;
  font-weight: normal;
}
.space {
  clear: both;
  padding: 5px;
  width: 17cm;
  margin: 0 auto;
}
.content {
  margin: 0 auto;
  width: 17cm;
  position: relative;
}
.col1 {
  width: 48%;
  float: left;
  text-align: left;
  padding-right: 1%;
}
.col2 {
  width: 48%;
  float: right;
  text-align: right;
  padding-left: 1%;
}
.long {
  min-height: 7.5cm;
}
.medium {
  min-height: 3cm;
}
.footer {
  position: relative;
  margin-top: 40px;
}
hr {
  border: 0;
  background-color: #fff;
  border-top: 1px dashed #000;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}
code {
  color: #000;
  float: none;
  background: #fff none repeat scroll 0% 0%;
  font-weight: normal;
  font-size: 10px;
}
a {
  color: #2b75bf;
}
a:hover {
  color: #284a6b;
  text-decoration: none;
}
a:focus {
  color: #284a6b;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 5px;
}
/* --------------------------------
		Linhas tabelas e html
--------------------------------- */
table {
  font-weight: 700;
  width: 17cm;
  margin: 0 auto;
  border-collapse: collapse; /* CSS2 */
  background: #fff;
}
table b {
  font-weight: 800;
}
table td {
  vertical-align: top;
  min-width: 4cm;
  border: 2px solid black;
  padding: 5px;
}
table td .col11 {
  vertical-align: top;
  border: 2px solid black;
  padding: 5px;
}
table th {
  border: 12px solid black;
  background: #fff;
}
/* --------------------------------
			formularios
--------------------------------- */
input {
  float: right;
  background: #3498db;
  margin: 5px;
  border-radius: 4px;
  border: 0;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  text-decoration: none;
  cursor: pointer;
}
input:hover {
  background: #1179be;
}
/* ==========================================================================
   Estilos para impressão
   ========================================================================== */
@media print {
  * {
    background: white !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  .boloifixo {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    color: #000;
  }
  h1 {
    font-size: 24pt;
  }
  h2 {
    font-size: 18pt;
  }
  h3 {
    font-size: 14pt;
  }
  q:after {
    content: " (" attr(cite) ")";
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a:link:after,
  a:visited:after {
    content: " (" attr(href) ") ";
  }
  p a {
    word-wrap: break-word;
  }
  p {
    widows: 3;
  }
  p {
    orphans: 3;
  }
  .page-break {
    page-break-before: always;
  }
  .notprint {
    visibility: hidden;
    display: none;
  }
  .pb {
    filter: -ms-grayscale(100%) !important;
    filter: -moz-grayscale(100%) !important;
    filter: -webkit-grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }
}
.folha {
  page-break-after: always;
  max-height: 27.5cm;
  background-color: white;
}
</style>
